import {
  HomePage,
  ChecklistsPage,
  ProfilePage,
  ScheduleChecklistsPage,
  ReportsPage,
  QualityCheckDetailPage,
  AboutPage,
  AddChecklistPage
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/checklists",
    element: ChecklistsPage,
    breadcrumb: "Checklists",
  },
  {
    path: "/profile",
    element: ProfilePage,
    breadcrumb: "Profile",
  },
  {
    path: "/home",
    element: HomePage,
    breadcrumb: "Home",
  },
  {
    path: "/schedulechecklists",
    element: ScheduleChecklistsPage,
    breadcrumb: "Schedule Checklists",
  },
  {
    path: "/reports",
    element: ReportsPage,
    breadcrumb: "Reports",
  },
  {
    path: "/reports/details/:id",
    element: QualityCheckDetailPage,
    breadcrumb: "Report Details",
  },
  {
    path: "/about",
    element: AboutPage,
    breadcrumb: "About",
  },
  {
    path: "/checklists/new",
    element: AddChecklistPage,
    breadcrumb: "Add New Checklist",
  },
  {
    path: "/checklists/:id",
    element: AddChecklistPage,
    breadcrumb: "Checklist Details",
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
