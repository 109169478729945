import HttpClient from "./httpClient";
import { ChecklistRequest } from "./types/checklistRequest";
import { Checklist } from "./types/checklist";
import { PagedList } from "./types/pagedList";

const baseUrl = '/api/checklists';
const getUrl = (searchParams: URLSearchParams): string => (`${baseUrl}?${searchParams}`);
const getByIdUrl = (id: string): string => (`${baseUrl}/${id}`);

export default class ChecklistsApi {

    static async getChecklists(
        fetchSchedules: boolean,
        skip: number,
        take: number):Promise<PagedList<Checklist>> {

            const queryParams = new URLSearchParams({
                fetchSchedules: fetchSchedules.toString(),
                skip: skip.toString(),
                take: take.toString(),
            });

        var response = await HttpClient.get(getUrl(queryParams));
        return await response.json();
    }
    static async getChecklistById(
        id: string):Promise<Checklist> {

        var response = await HttpClient.get(getByIdUrl(id));
        return await response.json();
    }

    static async updateChecklist(
    id: string,
    checklist: ChecklistRequest
    ): Promise<{ status: number; data?: Checklist; id?: number; errorMessage?: string; succeeded: boolean }> {
        try {
            const response = await HttpClient.put(getByIdUrl(id), {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checklist),
            });

            if (response.ok) {
                const data = await response.json();
                return {
                    status: response.status,
                    data: data,
                    id: data.id,
                    succeeded: true,
                };
            } else {
                const errorText = await response.text();
                return {
                    status: response.status,
                    errorMessage: errorText || `Request failed with status ${response.status}`,
                    succeeded: false,
                };
            }
        } catch (error) {
            throw new Error("An unexpected error occurred while updating the checklist.");
        }
    }
    static async addChecklist(
    checklist: ChecklistRequest
    ): Promise<{ status: number; id?: number; succeeded: boolean; errorMessage?: string }> {
        try {
            const response = await HttpClient.post(baseUrl, {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checklist),
            });
            if (response.ok) {
                const data = await response.json();
                return {
                    status: response.status,
                    id: data.id,
                    succeeded: true,
                };
            } else {
                const errorText = await response.text();
                return {
                    status: response.status,
                    errorMessage: errorText || `Request failed with status ${response.status}`,
                    succeeded: false,
                };
            }
        } catch (error) {
            throw new Error("An unexpected error occurred while adding the checklist.");
        }
    }
}