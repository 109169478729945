import React, { useState, useEffect, useRef } from "react";
import "../addchecklists/addchecklists.scss";
import notify from "devextreme/ui/notify";
import { Button, LoadIndicator, TextArea, TextBox } from "devextreme-react";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import ChecklistsApi from "../../../api/checklistsApi";
import { useNavigate, useParams } from "react-router-dom";
import { ChecklistQuestion } from "../../../api/types/checklistQuestion";
import ChecklistQuestionsApi from "../../../api/checklistQuestionsApi";
import { QuestionRequest } from "../../../api/types/questionRequest";
import { answerTypes } from "../types/answerTypes";

export default function AddEditChecklist() {
  const params = useParams<{ id: string }>();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState<ChecklistQuestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dataGridRef = useRef<DataGrid>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      ChecklistsApi.getChecklistById(params.id)
        .then((response) => {
          if (response) {
            const { name, code, description, questions } = response;
            setName(name);
            setCode(code);
            setDescription(description);
            setQuestions(questions || []);
          } else {
            notify("Failed to load checklist data.", "error", 2500);
          }
        })
        .catch(() => {
          notify("Failed to load checklist data.", "error", 2500);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params.id]);

  const handleDiscardChanges = () => {
    if (window.confirm("Are you sure you want to discard changes?")) {
      navigate('/checklists', { replace: true });
    }
  };
  const mapChecklistQuestionToRequest = (
    question: ChecklistQuestion,
    idChecklist: number
  ): QuestionRequest => {
    return {
      sequenceNo: question.sequenceNo || 0,
      hint: question.hint || "",
      description: question.description || "",
      answerType: question.answerType || 0,
      idChecklist: idChecklist,
    };
  };
  const handleSaveChecklist = async () => {
  setIsLoading(true);

  if (!name) {
    notify("Please fill in the name.", "error", 2500);
    setIsLoading(false);
    return;
  }

  const dataGrid = dataGridRef.current?.instance;
  if (!dataGrid) {
    notify("DataGrid is not initialized.", "error", 2500);
    setIsLoading(false);
    return;
  }

  const updatedQuestions: ChecklistQuestion[] = dataGrid
    .getDataSource()
    .items()
    .map((item: any) => ({
      ...item,
    }));

  const checklist = { name, code, description };

  try {
    const result = params.id
      ? await ChecklistsApi.updateChecklist(params.id, checklist)
      : await ChecklistsApi.addChecklist(checklist);

    if (result.succeeded) {
      const checklistId = params.id || result.id;

      const questionRequests = updatedQuestions.map((q) =>
        mapChecklistQuestionToRequest(q, Number(checklistId))
      );

      const upsertResult = await ChecklistQuestionsApi.upsertQuestions(
        Number(checklistId),
        questionRequests
      );

      if (upsertResult.success) {
        setQuestions(upsertResult.questions || []);
        notify(
          `Checklist ${params.id ? "updated" : "added"} successfully.`,
          "success",
          2500
        );

        if (!params.id) {
          navigate(`/checklists/${result.id}`, { replace: true });
        }
      } else {
        notify(
          `Failed to upsert questions: ${upsertResult.message}`,
          "error",
          2500
        );
      }
    } else {
      notify(`Failed to save checklist: ${result.errorMessage}`, "error", 2500);
    }
  } catch (error) {
    notify(
      "An unexpected error occurred while saving the checklist.",
      "error",
      2500
    );
  } finally {
    setIsLoading(false);
  }
};

  return (
    <React.Fragment>
      <h2 className="content-block">
        {params.id ? "Edit Checklist" : "Add Checklist"}
        <Button
          className="add-checklist-button"
          disabled={isLoading}
          text="Save"
          stylingMode="contained"
          type="default"
          onClick={handleSaveChecklist}
        />
        <Button
          disabled={isLoading}
          text="Discard"
          stylingMode="outlined"
          type="default"
          onClick={handleDiscardChanges}
        />
      </h2>
      <div className="form-group">
        <LoadIndicator className="loading-overlay" visible={isLoading} />
        <div className="dx-card responsive-paddings">
          <TextBox
            className="add-checklist-input"
            disabled={isLoading}
            value={name}
            placeholder="Enter name here..."
            label="Name *"
            onValueChanged={(e) => setName(e.value)}
          />
          <TextBox
            className="add-checklist-input"
            disabled={isLoading}
            value={code}
            placeholder="Enter code here..."
            label="Code"
            onValueChanged={(e) => setCode(e.value)}
          />
          <TextArea
            className="add-checklist-input"
            autoResizeEnabled={true}
            disabled={isLoading}
            value={description}
            placeholder="Enter description here..."
            label="Description"
            onValueChanged={(e) => setDescription(e.value)}
          />
          <h2>Questions</h2>
          <DataGrid
            disabled={isLoading}
            ref={dataGridRef}
            dataSource={questions}
            showBorders={true}
            columnAutoWidth={true}
          >
            <Editing mode="cell" allowAdding={true} allowUpdating={true} allowDeleting={true} />
            <Column dataField="description" caption="Description" />
            <Column dataField="hint" caption="Hint" />
            <Column dataField="answerType" caption="Answer Type">
              <Lookup dataSource={answerTypes} valueExpr="answerType" displayExpr="name" />
            </Column>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}